<template>
	<v-menu
		bottom
		left
		offset-y
		origin=""
		transition="scale-transition"
		:close-on-content-click="closeOnClick"
	>
		<template v-slot:activator="{ attrs, on }">
			<v-btn class="text-center mx-auto pa-0" min-width="0" text v-bind="attrs" v-on="on">
				<v-badge
					v-if="$store.state.user.notificaciones.length > 0"
					color="red"
					overlap
					bordered
				>
					<template v-slot:badge>
						<span>{{ $store.state.user.notificaciones.length }}</span>
					</template>

					<v-icon class="icon-25">mdi-bell-outline</v-icon>
				</v-badge>
				<v-badge v-else overlap bordered>
					<v-icon>mdi-bell-outline</v-icon>
				</v-badge>
			</v-btn>
		</template>
		<!--
    <v-list
      :tile="false"
      nav
      style="
        width: 600px;
        max-height: 400px;
        /*overflow-x: auto;*/
        /*display: inline-table;*/
      "
    >
      <v-row

        class="row pr-0 mr-0"
        style="width: 600px"
      >
        <v-col   class="col col-12 ma-0 pa-0">
          <div class="container border-0">
            <div class="left"><h3>Notificaciones</h3></div>
            <small class="right">
              <v-btn
                color="primary"
                depressed
                text
                tile
                x-small
                title="Ver un listado de todas las notificaciones recibidas"


                @click="$router.push({ path: '/notificaciones_usuarios' })"
                >Ver Todas</v-btn
              >
            </small>
          </div>

          <div
            role="list"
            class="v-list v-sheet v-list--two-line ma-0 pa-0 pl-3"
            v-if="$store.state.user.notificaciones.length > 0"
          >
            <v-card
              tabindex="-1"
              role="listitem"
              class="v-list-item pt-0 mt-1"
              fluid
              fill-height
              v-for="(n, i) in $store.state.user.notificaciones"
              :key="`item-${i}`"
            >
              <img
                class="v-avatar v-list-item__avatar ml-1 mr-2"
                src="/img/acara_logo_2.png"
                style="height: 40px; min-width: 40px; width: 40px"
              />

              <div
                class="v-list-item__content pt-0 mt-2"
                style="display: inline-table"
              >
                <div class="d-flex justify-end">
                  <v-icon
                    small
                    class="mr-2"
                    style="color: #999"
                    title="Ver"
                    @click="showNotificacion(n, 1)"
                  >
                    mdi-eye-outline
                  </v-icon>
                  <v-icon
                    medium
                    class="mr-2"
                    style="color: #999"
                    title="Marcar como leida"
                    @click="changeNotificationStatus(n, 1)"
                  >
                    mdi-email-open
                  </v-icon>
                </div>
                <div class="v-list-item__title truncate">
                  {{ n.titulo }}
                </div>

                <div class="v-list-item__subtitle truncate">
                  {{ n.mensaje }}
                </div>

                <div class="d-flex justify-end">
                  <p
                    class="mb-0 pl-0 pr-6 text--disabled"
                    style="font-size: 9px"
                  >
                    {{ n.created_at }}
                  </p>
                </div>
              </div>
            </v-card>

          </div>

          <div v-else class="v-sheet ma-0 pa-0">
            <div class="v-subheader theme--light mt-2 ml-2">
              <h3>No hay notificaciones</h3>
            </div>
          </div>
        </v-col>
      </v-row>


      <v-container fill-height fluid v-if="$store.state.user.notificaciones.length > 0">
        <v-row class="row pr-0 mr-0" style="width: 600px">
          <v-col class="col col-12 ma-0 pa-0">
            <div class="container">
              <div class="left"></div>
              <div class="right">
                <v-btn
                  color="primary"
                  title="Ver un listado de todas las notificaciones recibidas"
                  small
                  @click="$router.push({ path: '/notificaciones_usuarios' })"
                  >Ver Todas</v-btn
                >
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-list>
  -->
		<v-card class="mx-auto my-0" max-width="500" min-width="500">
			<v-toolbar class="white primary--text pl-0">
				<v-toolbar-title class="pl-0"
					><v-icon class="primary--text">mdi-bell</v-icon
					><span class="ml-2">Notificaciones</span></v-toolbar-title
				>

				<v-spacer></v-spacer>

				<v-btn
					color="white"
					class="primary--text"
					outlined
					x-small
					title="Ver un listado de todas las notificaciones recibidas"
					@click="$router.push({ path: '/notificaciones_usuarios' })"
					>Ver Todas</v-btn
				>
			</v-toolbar>

			<v-list
				max-height="400"
				style="overflow-y: scroll"
				two-line
				v-if="$store.state.user.notificaciones.length > 0"
			>
				<v-list-item-group>
					<template v-for="(item, i) in $store.state.user.notificaciones">
						<v-list-item :key="`item-${i}`" class="pl-2">
							<template v-slot:default="">
								<v-list-item-avatar class="pa-0">
									<v-img
										:alt="`${item.titulo} avatar`"
										src="/img/acara_logo_2.png"
										class="pa-0"
									></v-img>
								</v-list-item-avatar>
								<v-list-item-content>
									<v-list-item-title class="font-weight-black">{{
										item.titulo
									}}</v-list-item-title>

									<v-list-item-subtitle>{{
										item.mensaje
									}}</v-list-item-subtitle>
								</v-list-item-content>

								<v-list-item-action>
									<v-list-item-action-text
										><v-icon
											small
											class="mr-2"
											style="color: #999"
											title="Ver"
											@click="showNotificacion(item, 1)"
										>
											mdi-eye-outline
										</v-icon>
										<v-icon
											medium
											class="mr-2"
											style="color: #999"
											title="Marcar como leida"
											@click="changeNotificationStatus(item, 1)"
										>
											mdi-email-open
										</v-icon></v-list-item-action-text
									>
									<v-list-item-action-text
										><small class="blue--text">{{
											item.created_at
										}}</small></v-list-item-action-text
									>
								</v-list-item-action>
							</template>
						</v-list-item>
					</template>
				</v-list-item-group>
			</v-list>
			<v-list two-line v-else>
				<v-list-item-group multiple>
					<template>
						<v-list-item>
							<template v-slot:default="">
								<v-list-item-content>
									<v-list-item-title>No hay notificaciones</v-list-item-title>
								</v-list-item-content>
							</template>
						</v-list-item>
					</template>
				</v-list-item-group>
			</v-list>
		</v-card>
	</v-menu>
</template>
<style scoped>
.truncate {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 500px; /* Adjust based on your layout */
}

.container {
	display: grid;
	grid-template-columns: 1fr 1fr;
	align-items: center;
	padding: 10px;
	border: 1px solid #ccc;
}

.left {
	padding: 10px;
}

.right {
	justify-self: end;

	padding: 10px;
}
</style>
<script>
	export default {
		name: "DashboardCoreNotification",

		props: {},

		data: () => ({
			notifications: [],
			closeOnClick: false,

			routeNotificaciones: "notificacionesChangeStatus",
		}),

		created() {},
		mounted: function () {},
		methods: {
			async showNotificacion(item, estado) {
				this.changeNotificationStatus(item, estado);
				this.$router.push({ path: item.link });
			},
			async changeNotificationStatus(item, estado) {
				let formData = {
					notificacion_id: item.id,
					usuario_id: this.$store.state.user.id,
					leida: estado,
				};
				this.$axiosApi.post(this.routeNotificaciones, formData).then(
					(response) => {
						console.log(response);
						if (response.status == 200) {
							this.$getNotificaciones();
						} else {
							console.log(response);
						}
					},
					(error) => {
						console.log(error);
					}
				);
			},
		},
	};
</script>
